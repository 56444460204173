<template>
  <!--  <div style="background-image:url('/images/lingqiantop.png')">-->
    <div style="background-color:#f8a900">
        <div class="header" style="background:none">
            <van-icon name="arrow-left" color="#fff" @click="$router.push({path: '/self/service/bill'})"/>
            <div class="title"></div>
            <div class="bill" @click="toLicaiBill"><van-icon color="#fff" name="weapp-nav" /></div>
        </div>
          <div
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: .7rem;
       
      "
    >
    <div style="width:2.5rem;height:2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background:#fff;
        border-radius:50%">
      <van-image
        style="text-align: center;"
        round
        width="1.5rem"
        height="1.5rem"
        src="/images/lintu.png"
      />
    </div>
    </div>
    <div
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: .5rem;
      "
    >
      <span style="font-size: 20px;color:#fff">零钱通</span>
    </div>
    <div
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: .5rem;
      "
    >
      <van-icon color="#fff" size="20px" name="/images/zijinan.png" /><span style="font-size: 15px;color:#fff">资金安全保障中 </span><van-icon color="#fff" name="arrow" />
    </div>
    <div
      style="
        width: 96%;
      
        margin-top: 1.5rem;
        height:26rem;
        background:#fff;
        margin-left:2%;
        border-radius:2%
      "
    >
     <div
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 2rem;
      "
      @click="toLicaiBill"
    >
      <span style="font-size: 15px;">账号余额</span>
    </div>  
    <div
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    
      "
      @click="toLicaiBill"
    >
     <span style="text-align:25px;font-size: 30px; font-weight: 700;padding-bottom:.4rem;margin-right:.4rem">¥</span> <span style="font-size: 40px; font-weight: 700">{{  this.fund.coinCash }}</span>
    </div>
     <div
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
     
      "
      @click="toLicaiBill"
    >
      <span style="font-size: 15px;color:#576b95">资金明细</span>
    </div>    
      <div
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top:2rem
      "
    >
      <div
      style="
        width: 50%;

      "
    >
   <div style="display: flex;
        justify-content: center;
        align-items: center;">
            <span style="font-size: 15px;color:#999">7日年化收益率&nbsp;&nbsp;</span><van-icon color="#999" size="15px" name="arrow" />
          
     </div>
     <div style="display: flex;
        justify-content: center;
        align-items: center;">
           <span style="font-size: 25px; font-weight: 400">1.3690%</span>
          
     </div>
     <div style="display: flex;
        justify-content: center;
        align-items: center;
   
        ">
           <span style="font-size: 12px; padding:0.2rem;background:#f7f7f7;border-radius:10px;color:#7c7c7c">&nbsp;&nbsp;华夏现金增利货币A/E&nbsp;&nbsp;</span>
          
     </div>  
  
  
  
    </div> 
    <div
      style="
        width: 50%;
 
     
      "
    >
        
     <div style="display: flex;
        justify-content: center;
        align-items: center;">
            <span style="font-size: 15px;color:#999">累计收益&nbsp;&nbsp;</span><van-icon color="#999" size="15px" name="arrow" />
          
     </div>
     <div style="display: flex;
        justify-content: center;
        align-items: center;">
           <span v-if="fund.shouyi" style="font-size: 25px; font-weight: 400">¥{{ fund.shouyi }}</span>
           <span v-if="!fund.shouyi" style="font-size: 25px; font-weight: 400">¥2319.73</span>
          
     </div>
     <div style="display: flex;
        justify-content: center;
        align-items: center;
   
        ">
           <span v-if="fund.shouyi" style="font-size: 12px; padding:0.2rem;background:#f7f7f7;border-radius:10px;color:#7c7c7c">&nbsp;&nbsp;昨日 ¥{{ fund.zuorishouyi}}&nbsp;&nbsp;</span>
           <span v-if="!fund.shouyi" style="font-size: 12px; padding:0.2rem;background:#f7f7f7;border-radius:10px;color:#7c7c7c">&nbsp;&nbsp;昨日 ¥{{ 17.36}}&nbsp;&nbsp;</span>
          
     </div>
    </div>
    

    </div>     
<!--3-->
      <div
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top:2rem
      "
    >
    <div
      style="
        width: 50%;
        font-color:#f2b100
     
      "
    >
        
   <van-button @click="$router.push({ path: '/self/service/licaizc' })" style="margin-left:30%;border-radius:6px;width:60%;color:#f2b100;background:#f2f2f2;border: 1px solid #f2f2f2;font-size:16px"  type="primary">转出</van-button>
   
    </div>
    
    <div
      style="
        width: 50%;
 
     
      "
    >
        
   <van-button  @click="$router.push({ path: '/self/service/licaizr' })" style="margin-left:5%;border-radius:6px;width:60%;background:#f2b100;border: 1px solid #f2b100;font-size:16px" type="primary">转入</van-button>
   
    </div>
    
    </div>
<!--4-->   
    <div
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1.5rem;
      "
    >
      <van-icon color="#ffc300" size="15px" name="/images/shizhong.png" /><span style="font-size: 15px;color:#808080">&nbsp;&nbsp;&nbsp;设置定时转入 </span><van-icon color="#808080" name="arrow" />
    </div>
    </div>
    
    
     <div
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 2rem;
      "
    >
      <img style="width:100%;height:23rem;" src="/images/licaidi.jpg"/>
    </div>  
    
    
    </div>

</template>

<script>
import { mapState } from 'vuex'


export default {
    data() {
        return {
            payList: [
                { id: 0, text: '分付', icon: '/images/pay-code.png' },
                { id: 1, text: '支付分', icon: '/images/pay-code.png' },
                { id: 2, text: '消费者保护', icon: '/images/consumer-protection.png' },
            ]
        }
    },
    computed: {
        ...mapState({
            fund: state => state.fund
           
        }),
        list() {
            this.fund.coin = this.returnFloat(this.fund.coin)
            this.fund.coinCash = this.returnFloat(this.fund.coinCash)
            return [
                { id: 0, text: '零钱', icon: '/images/cash2.png', value: `¥${ this.fund.coin}` },
                { id: 1, text: '零钱通', icon: '/images/zero-down-pay.png', value: `¥${this.fund.coinCash}`, tip: '收益率1.85%' },
                { id: 2, text: '银行卡', icon: '/images/bank-card.png', value: '' },
                { id: 3, text: '亲属卡', icon: '/images/relatives-card.png', value: '' }
            ]
        }

    },  mounted() {
       
           // this.bianshe()
            
            
        },
    methods: {

        toDetail(id) {
         
            if (id==2) {
                this.$router.push({ path: '/self/service/bill/banklist'})
            }
            if (id==0) {
                this.$router.push({ path: '/self/service/chongzhi'})
            }
        },
        toBill() {
            this.$router.push({ path: '/self/service/bill/billList'})
        },
        returnFloat(num) {
    num = num.toString().replace(/,/g, ""); // 转成字符串类型  如能确定类型 这步可省去
      console.log(num)
      if (num.indexOf(".") !== -1) {
        let [integerPart, decimalPart] = num.split(".");
     
        if (decimalPart.length > 2) {
          decimalPart = decimalPart.substring(0, 2);
        } else if (decimalPart.length === 1) {
          decimalPart += "0";
        }
     
        num = `${integerPart}.${decimalPart}`;
      } else {
        num += ".00";
      }
     
      return num;
    },
    bianshe(){
           var metaThemeColor = document.querySelector('meta[name="theme-color"]');
             metaThemeColor.setAttribute('content', '#000'); // 这里将主题色设置为 FF5733
    },
    toLicaiBill() {
      this.$router.push({ path: '/self/service/licaibill'})
    }
    }
}


</script>
<style lang="less" scoped>
.header {
    position: relative;
    z-index: 99;
    // overflow: hidden;
    height: 45px;
    display: flex;
    align-items: center;
    // font-size: 15px;
    padding: 0 15px 0 10px;
    line-height: 45px;
    background: #fff;
    opacity: 1;
    color: #000;
    user-select: none;
    -webkit-user-select: none;
    transition: all 0.3s linear;
    text-align: center;
    font-size: 17px;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Noto Sans CJK SC, WenQuanYi Micro Hei, Arial, sans-serif;
    .title {
        flex: 1;
        margin-left: 17px;
    }
    .bill {
        font-weight: 400;
        font-size: 14px;
    }
}
.item {
    background: #fff;
    width: 100%;
    padding-left: 10px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    .icon {
        padding-bottom: 12px;
        img {
            width: 26px;
            height: 26px;
            margin-right: 18px;
        }
    }
    .con {
        flex: 1;
        display: flex;
        align-items: center;
        padding-bottom: 18px;
        border-bottom: 1px solid #f2f2f2;
        font-size: 14px;
        .text {
            flex: 1;
            span {
                font-size: 10px;
                font-weight: 500;
                color: #F1B067;
                margin-left: 6px;
            }
        }
        .value {
            padding-right: 8px;
        }
        .van-icon {
            margin-top: 0px;
            margin-right: 10px;
        }
    }
}
.item:nth-of-type(4) {
    .icon {
        img {
            width: 20px;
            height: 20px;
            margin-left: 3px;
            margin-right: 20px;
        }
    }
}
.pay {
    background: #fff;
    width: 100%;
    padding-left: 10px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    .icon {
        padding-bottom: 16px;
        img {
            width: 26px;
            height: 26px;
            margin-right: 18px;
        }
    }
    .con {
        flex: 1;
        display: flex;
        align-items: center;
        padding-bottom: 18px;
        border-bottom: 1px solid #f2f2f2;
        .text {
            flex: 1;
            span {
                font-size: 10px;
                font-weight: 500;
                color: #F1B067;
                margin-left: 6px;
            }
        }
        .value {
            padding-right: 8px;
        }
        .van-icon {
            margin-top: -2px;
            margin-right: 10px;
        }
    }
}
.bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    div {
        color: #6a7ba1;
    }
    .split {
        margin-left: 6px;
        margin-right: 6px;
        color: rgb(204, 204, 204);
    }
}
</style>